import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
//import Image from "../components/image"
import SEO from "../components/seo"
import ReactMarkdown from "react-markdown"
import rehypeRaw from 'rehype-raw'
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';


const HomePage = ({ data: { strapiHome }}) => (
  <Layout>
   
    <SEO title="PETNECK2 Home" />

    <div class="content">
      <div class="title">
        <p><ReactMarkdown rehypePlugins={[rehypeRaw]}>{strapiHome.content.Title}</ReactMarkdown></p>
      </div>
      <div class="col1">
        <p><ReactMarkdown rehypePlugins={[rehypeRaw]}>{strapiHome.content.Column1}</ReactMarkdown></p>
      </div>
      <div class="col2">
        <p><ReactMarkdown rehypePlugins={[rehypeRaw]}>{strapiHome.content.Column2}</ReactMarkdown></p>
      
          <div class="Twitter_Feed">
            <TwitterTimelineEmbed sourceType="profile" screenName="petneck2" options={{height: 400}} />
            <TwitterFollowButton screenName={'petneck2'} />        
          </div>
      </div>
      <div class="col3">
        <p><ReactMarkdown rehypePlugins={[rehypeRaw]}>{strapiHome.content.Bottom_text}</ReactMarkdown></p>

        <h3>Thank you to all our collaborative partners</h3>

        <div class="partners padding-bottom">

          <a href="https://www.birmingham.ac.uk/index.aspx"> <img width="300px" aria-label="University of Birmingham" src="https://petneck2docs.s3.eu-west-2.amazonaws.com/logos/1_university_of_birmingham.png"/></a>

          <a href="https://www.uhb.nhs.uk"><img width="300px" aria-label="University Hospital of Birmingham" src="https://petneck2docs.s3.eu-west-2.amazonaws.com/logos/2_university_hospital_birmingham.jpg"/></a>

          <a href="https://www.bristol.ac.uk"><img width="300px" aria-label="University of Bristol" src="https://petneck2docs.s3.eu-west-2.amazonaws.com/logos/3_University_of_Bristol_logo.png"/></a>

          <a href="https://www.uhbw.nhs.uk"><img width="300px" aria-label="University Hospital of Bristol" src="https://petneck2docs.s3.eu-west-2.amazonaws.com/logos/4_university_hospital_bristol_weston_3.png"/></a>

          <a href="https://www.imperial.ac.uk"><img width="300px" aria-label="Imperial College" src="https://petneck2docs.s3.eu-west-2.amazonaws.com/logos/5_imperial_college.jpg"/></a>

          <a href="https://www.inhanse.org"><img width="200px" aria-label="Inhanse" src="https://petneck2docs.s3.eu-west-2.amazonaws.com/logos/6_inhanse.jpg"/></a>

          <a href="https://www.ox.ac.uk"><img width="300px" aria-label="University of Oxford" src="https://petneck2docs.s3.eu-west-2.amazonaws.com/logos/7_University_of_Oxford-Logo.wine_2.png"/></a>

          <a href="https://www.brookes.ac.uk"><img width="300px" aria-label="Oxford Brookes" src="https://petneck2docs.s3.eu-west-2.amazonaws.com/logos/8_oxford_brookes.png"/></a>

          <a href="https://www.stricklandscanner.org.uk"><img width="300px" aria-label="Paul Strickland Cancer Centre" src="https://petneck2docs.s3.eu-west-2.amazonaws.com/logos/9_paul_strickland_cancer_centre.png"/></a>

          <a href="https://fundingawards.nihr.ac.uk/award/NIHR200861"><img width="300px" aria-label="National Institute for Health Research" src="https://petneck2docs.s3.eu-west-2.amazonaws.com/logos/10_funded_by_NIHR.png"/></a>
          
        </div>
      
      </div>

    </div>

    
  </Layout>
)

export const query = graphql`
  query HomePageQuery {
    strapiHome {
      content {
        Title
        Column1
        Column2
        Bottom_text
      }
      created_at
    }
  }
`

export default HomePage
